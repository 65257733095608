import { useState } from 'react'
import PropTypes from 'prop-types'
import cns from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { LANG_BR, LANG_ES, LANGS_FOR_DEMO } from '@constants/index'

import useLanguageUrl from '@utils/useLanguageUrl'
import $amplitude from '@utils/amplitude'
import useFormatMessage from '@utils/useFormatMessage'
import { eventLearnMoreButtonClick } from '@utils/amplitudeEvents'

import BrandsNew from '@components/brandsNew/brandsNew'
import DemoModal from '@components/demoModal/DemoModal/DemoModal'
import SuccessModal from '@components/demoModal/SuccesModal/SuccessModal'
import Breadcrumbs from '@components/layouts/breadcrumbs/breadcrumbs'
import ArrowDown from '@icons/arrow-down.svg'

import styles from './hero.module.scss'

const imagesFolder = 'screenshots'

export default function Hero({
  isFree = false,
  hideInfo,
  isLongTitle,
  isShortPage,
  isSmallPadding,
  hasDescriptionLink,
  setHideInfo,
  setIsShortPage,
  pageId,
  data,
  noPaddingBottom,
  brands,
  breadcrumbsList = null,
  buttonText = 'hero.buttonText',
  showBenefits,
}) {
  const { locale } = useRouter()
  const t = useFormatMessage()
  const langUrl = useLanguageUrl('signup', pageId)

  const { img, classModifier, signupButton, demoLink, linkToScroll } = data

  const isPrimary = !!data.primary
  const isBrasilianLocale = locale === LANG_BR
  const isEsLocale = locale === LANG_ES
  const isShowDemo = LANGS_FOR_DEMO.includes(locale)

  const buttonTheme = isPrimary ? 'primary' : 'secondary'
  const shouldExtendWrapper = pageId !== 'team'

  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const handleRegistrationButtonClick = () => {
    $amplitude('[Landing] Registration Button Click', {
      page_name: pageId,
      block_name: 'first_screen',
    })
  }

  const handleHowItWorksButtonClick = () => {
    $amplitude('[Landing] Autoposting How It Works Click ')
  }

  const handleOpenDemoModal = () => {
    setIsDemoModalOpen(true)
    $amplitude('[Landing] Demo Button Click', { page_name: pageId })
    $amplitude('[Landing] Demo Modal Window View')
  }

  const handleCloseDemoModal = () => {
    setIsDemoModalOpen(false)
  }

  const handleOpenSuccessModal = () => {
    setIsDemoModalOpen(false)
    setIsSuccessModalOpen(true)
  }
  const handleCloseSuccessModal = () => setIsSuccessModalOpen(false)

  const toggleHideInfo = () => {
    eventLearnMoreButtonClick()
    setHideInfo(!hideInfo)
    setIsShortPage(!isShortPage)
  }

  const titleClassName = cns(
    styles.hero__title,
    classModifier && styles[`hero__title_${classModifier}`],
    isLongTitle && styles.hero__title_long,
    locale === 'br' &&
      pageId === 'home' &&
      styles[`hero__title_${classModifier}Br`],
    locale === 'br' &&
      pageId === 'statistika-akkaunta-tik-tok' &&
      styles[`hero__title_${classModifier}Br`],
    isPrimary ? `main-title` : `page-title`,
    isPrimary && `main-title--h1`
  )

  const titleStyles =
    locale === 'br' && pageId === 'statistika-akkaunta-tik-tok'
      ? { fontSize: '48px' }
      : { whiteSpace: 'pre-wrap' }

  const getTitleTag = () =>
    pageId === 'team' ? (
      <h2 className={titleClassName} style={titleStyles}>
        {t(`hero.titles.${pageId}`)}
      </h2>
    ) : (
      <h1 className={titleClassName} style={titleStyles}>
        {t(`hero.titles.${pageId}`)}
      </h1>
    )

  const renderImage = () => {
    if (!img) {
      return null
    }
    return (
      <picture>
        <source
          srcSet={`/images/${imagesFolder}/${locale}/${img}.webp 1x, /images/${imagesFolder}/${locale}/${img}@2x.webp 2x`}
          type="image/webp"
        />
        <source
          srcSet={`/images/${imagesFolder}/${locale}/${img}.png 1x, /images/${imagesFolder}/${locale}/${img}@2x.png 2x`}
          type="image/png"
        />
        <img
          className={cns(
            styles.hero__img,
            classModifier && styles[`hero__img_${classModifier}`],
            isBrasilianLocale &&
              pageId === 'monitoring' &&
              styles[`hero__img_${classModifier}Br`]
          )}
          src={`/images/${imagesFolder}/${locale}/${img}.png`}
          alt={t(`hero.titles.${pageId}`)}
        />
      </picture>
    )
  }

  return (
    <div
      className={cns(
        isPrimary ? styles.hero : styles.heroSecondary,
        noPaddingBottom && styles.heroSecondary_noPaddingBottom,
        isSmallPadding && styles.heroSecondary_smallPadding,
        isShortPage && styles.hero__shortPage
      )}
    >
      <div
        className={cns(
          isPrimary ? styles.hero__wrapper : styles.hero__secondaryWrapper,
          isPrimary &&
            classModifier &&
            styles[`hero__wrapper_${classModifier}`],
          !isPrimary &&
            classModifier &&
            styles[`hero__secondaryWrapper_${classModifier}`],
          'wrapper',
          { 'wrapper--extended': shouldExtendWrapper }
        )}
      >
        <Breadcrumbs list={breadcrumbsList} isFree={isFree} />

        <div
          className={cns(
            styles.hero__content,
            classModifier && styles[`hero__content_${classModifier}`],
            isLongTitle && styles.hero__content_longTitle
          )}
        >
          {showBenefits && (
            <p className={styles.hero__freePeriod}>
              {t('hero.benefits.freePeriod')}
            </p>
          )}

          {getTitleTag()}

          <p
            className={cns(
              styles.hero__text,
              classModifier && styles[`hero__text_${classModifier}`],
              `main-text main-text--big`
            )}
            dangerouslySetInnerHTML={
              hasDescriptionLink
                ? {
                    __html: t(`hero.descriptions.${pageId}`),
                  }
                : undefined
            }
          >
            {hasDescriptionLink ? null : t(`hero.descriptions.${pageId}`)}
          </p>
          <div
            className={cns({
              [styles.hero__actions]: isPrimary,
              [styles.hero__secondaryActions]: !isPrimary,
              [styles.hero__extraMargin]: !isBrasilianLocale,
            })}
          >
            {signupButton && (
              <div
                className={cns({
                  [styles.hero__action]: isPrimary,
                  [styles.hero__secondaryAction]: !isPrimary,
                  [styles[`hero__secondaryAction_${classModifier}`]]:
                    !isPrimary && classModifier,
                })}
              >
                <a
                  className={cns(
                    styles.hero__button,
                    `button`,
                    `button--${buttonTheme}`
                  )}
                  href={langUrl}
                  onClick={handleRegistrationButtonClick}
                  rel="nofollow"
                >
                  {t(buttonText)}
                </a>

                {linkToScroll && (
                  <Link
                    className={styles.hero__linkToScroll}
                    href={linkToScroll}
                    onClick={handleHowItWorksButtonClick}
                  >
                    {t(`hero.linkToScroll`)}
                  </Link>
                )}
              </div>
            )}

            {demoLink && isShowDemo && (
              <button
                className={styles.hero__demoLink}
                onClick={handleOpenDemoModal}
              >
                {t(`nav.links.demoFull`)}
              </button>
            )}
          </div>

          {/* 
              Закомментировал, так как нет текста для этих элементов
      */}

          {/* {isPrimary && isBrasilianLocale && (
            <div className={styles.hero__benefits}>
              {!isABTestForPathname2 && (
                <Benefits
                  benefits={getListFromLocales(
                    'hero.benefits',
                    heroBenefitsData,
                    FormattedMessage
                  )}
                />
              )}
            </div>
          )}

          {pageId === 'instagram-analytics' && isBrasilianLocale && (
            <div className={styles.hero__benefitsBr}>
              {!isABTestForPathname2 && (
                <Benefits
                  benefits={getListFromLocales(
                    'hero.benefits',
                    heroBenefitsData,
                    FormattedMessage
                  )}
                  royalBlue={true}
                />
              )}
            </div>
          )} */}
        </div>

        {isPrimary}

        {renderImage()}
        {hideInfo ? (
          <div className={styles.showMoreBlock}>
            <button className={styles.showMore} onClick={toggleHideInfo}>
              {t(`hero.showMore.button`)}
            </button>
            <ArrowDown />
          </div>
        ) : (
          brands && (
            <div
              className={cns(
                styles.hero__brands,
                classModifier && styles[`hero__brands_${classModifier}`]
              )}
            >
              <BrandsNew
                pageId={pageId}
                data={brands}
                title={t('hero.brands.title')}
              />
            </div>
          )
        )}
      </div>

      {isDemoModalOpen && (
        <DemoModal
          isOpen={isDemoModalOpen}
          handleOpenSuccessModal={handleOpenSuccessModal}
          onClose={handleCloseDemoModal}
          locale={locale}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleCloseSuccessModal}
        />
      )}
    </div>
  )
}

Hero.propTypes = {
  isFree: PropTypes.bool,
  isSmallPadding: PropTypes.bool,
  isLongTitle: PropTypes.bool,
  hasDescriptionLink: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  pageId: PropTypes.string,
  data: PropTypes.object,
}
