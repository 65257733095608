import { useRouter } from 'next/router'
import { LINK_SIGNIN, LINK_SIGNUP } from '@constants/index'

const getParams = (locale, pageId) => {
  if (pageId === 'partners') {
    return `language=${locale}&flow=referral`
  } else if (pageId === 'download-comments-instagram') {
    return `language=${locale}&flow=comments`
  }
  return `language=${locale}`
}

const useLanguageUrl = (route, pageId) => {
  const { locale } = useRouter()

  const url =
    route === 'signin'
      ? `${process.env.NEXT_PUBLIC_PRO_SITE_URL}${LINK_SIGNIN}`
      : `${process.env.NEXT_PUBLIC_PRO_SITE_URL}${LINK_SIGNUP}`


  const params = route === 'signup'
    ? getParams(locale, pageId)
    : `language=${locale}`

  return `${url}?${params}`
}

export default useLanguageUrl
